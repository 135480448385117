/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import React from "react"
import { Flex } from "@theme-ui/components"
import PageLink from "./page-link"
import Text from "./text"
import { renderLineBreak } from "../helpers"
import shareLink from "../images/facebook.svg"
import twitterLogo from "../images/twitter.svg"
import copyLinkImg from "../images/copy-link.svg"
import { useIsClient } from "../hooks/use-is-client"
import Row from "./row"
import Column from "./column"

const SpecificationsPressOverview = ({
  pathname,
  title,
  displayTitle,
  tableContent,
  titleStyle,
  ...restProps
}) => {
  const isClient = useIsClient()
  const socialLinkList = [
    {
      id: "twitter-link",
      alt: "Link to Twitter",
      href: `https://twitter.com/intent/tweet?text=${title}&url=${encodeURI(
        `${(isClient && window.location.href) ||
          `https://www.plantprefab.com${pathname}`}`
      )}`,
      src: twitterLogo,
    },
    {
      id: "share-link",
      alt: "Share",
      href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
        `${(isClient && window.location.href) ||
          `https://www.plantprefab.com${pathname}`}`
      )}`,
      src: shareLink,
    },
    {
      id: "copy-link",
      alt: "Copy Link",
      messageOnClick: "Copied to clipboard",
      action: () => {
        const text = window.location.href
        var textArea = document.createElement("textarea")
        textArea.value = text

        // Avoid scrolling to bottom
        textArea.style.top = "0"
        textArea.style.left = "0"
        textArea.style.position = "fixed"

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        document.execCommand("copy")
        document.body.removeChild(textArea)
      },
      src: copyLinkImg,
    },
  ]
  const styleList = {
    socialLinkList: {
      item: {
        flexShrink: 0,
        m: 0,
        mr: 3,
        ":last-child": { mr: 0 },
      },
    },
  }
  const [showMessage, setShowMessage] = React.useState({})
  return (
    <Flex
      sx={{
        flexDirection: "column",
        pt: theme => theme.spacing.vertical.sm,
        pb: theme => [
          theme.spacing.vertical.sm,
          theme.spacing.vertical.sm,
          theme.spacing.vertical.md,
        ],
      }}
      {...restProps}
    >
      <Text
        customStyle={{
          fontSize: [6, 6, 7],
          maxWidth: ["none", "none", 576],
          ...titleStyle,
        }}
        type="h1"
      >
        {displayTitle ? renderLineBreak(displayTitle) : title}
      </Text>
      <div
        sx={{
          justifyContent: "space-between",
          alignItems: "flex-end",
          display: ["block", "block", "block"],
          mt: [
            theme => theme.spacing.vertical.sm,
            theme => theme.spacing.vertical.sm,
            theme => theme.spacing.vertical.lg,
          ],
        }}
        id="pressCenter"
      >
        <Row>
          {/* 519-576 */}
          <Column
            size={[12, 12, 9]}
            customStyle={{
              pl: [0, 0, "10px", "10px"],
              ml: ["-5px", "-5px", 0, 0],
            }}
          >
            {tableContent && tableContent.length && (
              <div>
                {tableContent.map((contentData, index) =>
                  contentData.bodyText ? (
                    <React.Fragment>
                      {contentData.headingText && (
                        <Text customStyle={{ textTransform: "uppercase" }}>
                          {contentData.headingText}
                        </Text>
                      )}
                      <Text customStyle={{ fontWeight: "bold" }}>
                        {contentData.bodyText}
                      </Text>
                    </React.Fragment>
                  ) : null
                )}
              </div>
            )}
          </Column>
          <Column
            size={[12, 12, 3]}
            customStyle={{
              mt: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.sm,
                "0",
                "0",
              ],
            }}
          >
            <ul
              sx={{
                alignItems: "center",
                display: "flex",
                listStyle: "none",
                justifyContent: "flex-end",
                float: "right",
                paddingLeft: "120px",
                m: 0,
                ml: 0,
              }}
            >
              <li sx={{ ...styleList.socialLinkList.item, mr: 3 }}>
                <Text customStyle={{ textTransform: "uppercase" }}>Share:</Text>
              </li>
              {socialLinkList.map((link, index) => (
                <li
                  sx={{
                    position: "relative",
                    ...styleList.socialLinkList.item,
                    display: "flex",
                    ...(link.messageOnClick && {
                      "::after": {
                        content: `'${link.messageOnClick}'`,
                        position: "absolute",
                        top: showMessage[index]
                          ? ["-2px", "-2px", 28]
                          : ["-2px", "-2px", 20],
                        right: ["unset", "unset", 0],
                        left: showMessage[index]
                          ? [28, 28, "unset"]
                          : [24, 24, "unset"],
                        fontSize: 16,
                        whiteSpace: "nowrap",
                        fontFamily: "body",
                        color: "#777",
                        transition: "0.5s",
                        opacity: showMessage[index] ? "1" : "0",
                        pointerEvents: "none",
                      },
                    }),
                  }}
                  key={link.id}
                >
                  <PageLink
                    customStyle={{ display: "block", pr: 0, cursor: "pointer" }}
                    link={link.href}
                    onClick={() => {
                      setShowMessage({ ...showMessage, [index]: true })
                      setTimeout(
                        () =>
                          setShowMessage({ ...showMessage, [index]: false }),
                        2500
                      )
                      link && link.action()
                    }}
                  >
                    <img
                      sx={{ display: "block", width: 20 }}
                      src={link.src}
                      alt={link.alt}
                    />
                  </PageLink>
                </li>
              ))}
            </ul>
          </Column>
        </Row>
      </div>
    </Flex>
  )
}

SpecificationsPressOverview.propTypes = {
  pathname: PropTypes.string,
  tableContent: PropTypes.arrayOf(
    PropTypes.shape({
      bodyText: PropTypes.string,
      headingText: PropTypes.string,
    })
  ),
  title: PropTypes.string,
}

export default SpecificationsPressOverview
