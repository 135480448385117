/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import RichText from "../components/rich-text"
import SEO from "../components/seo"
import Container from "../components/container"
import SpecificationsPressOverview from "../components/specifications-press-overview"


const ProjectDetail = ({ data, location }) => {
  // const [showMoreContent, setShowMoreContent] = React.useState(0)
  let article = data.contentfulNews

  // console.log("news")
  // let initialContent = cloneDeep(article.fullStory)
  // let readMoreContent = cloneDeep(article.fullStory)
  // let readMoreIdx;
  // if (article.fullStory) {
  //   readMoreIdx = article.fullStory.json.content.findIndex(
  //     (node, idx) => {
  //       const nodeContent = get(node, "content[0].value", "")
  //       return nodeContent.trim() === "####"
  //     }
  //   )
  //   if (readMoreIdx) {
  //     initialContent.json.content = initialContent.json.content.slice(
  //       0,
  //       readMoreIdx
  //     )
  //     readMoreContent.json.content = readMoreContent.json.content.slice(
  //       readMoreIdx + 1,
  //       readMoreContent.json.length
  //     )
  //   }
  // }

  return (
    <React.Fragment>
      <SEO
        title={article.title}
        description={get(article, "metaDescription", "")}
        imageUrl={get(article, "image.file.url")}
      />
      <Container
        customStyle={{
          py: theme => theme.spacing.vertical.sm,
          pl: ["15px", "", ""],
        }}
      >
        <h5>{article.type}</h5>
        <SpecificationsPressOverview
          pathname={location.pathname}
          tableContent={[
            {
              bodyText: get(article, "teaser.teaser", ""),
            },
          ]}
          title={article.title}
          titleStyle={{
            fontSize: 26,
            fontWeight: "bold",
            mb: [0, 0, "-75px"],
            mt: -4,
          }}
        />

        {/* {article.fullStory &&
          <React.Fragment>
            {initialContent ? <RichText data={initialContent} /> : null}
            {readMoreIdx && !showMoreContent ? (
              <div
                onClick={() => setShowMoreContent(1)}
                onKeyDown={() => setShowMoreContent(1)}
                role="button"
                tabIndex="0"
                sx={{ cursor: "pointer" }}
              >
                <Text
                  customStyle={{
                    color: theme => theme.colors.primary,
                    mt: theme => theme.space[3],
                  }}
                >
                  Read more
                  <span
                    sx={{
                      fontSize: 14,
                      ml: 2,
                      display: "inline-block",
                      transform: "scaleX(1.8) translateY(-5px)",
                    }}
                  >
                    {"\u2304"}
                  </span>
                </Text>
              </div>
            ) : null}
            {readMoreIdx && showMoreContent ? (
              <div>
                <RichText data={readMoreContent} />
                <div
                  onClick={() => setShowMoreContent(0)}
                  onKeyDown={() => setShowMoreContent(0)}
                  role="button"
                  tabIndex="0"
                  sx={{ cursor: "pointer" }}
                >
                  <Text
                    customStyle={{
                      color: theme => theme.colors.primary,
                      mt: theme => theme.space[3],
                    }}
                  >
                    Read less
                    <span
                      sx={{
                        fontSize: 14,
                        ml: 2,
                        display: "inline-block",
                        transform: "scaleX(1.8) translateY(7px) rotate(180deg)",
                      }}
                    >
                      {"\u2304"}
                    </span>
                  </Text>
                </div>
              </div>
            ) : null}
          </React.Fragment>} */}
        <RichText data={article.fullStory} />
      </Container>
    </React.Fragment>
  )
}

export default ProjectDetail

export const query = graphql`
  query($slug: String!) {
    contentfulNews(slug: { eq: $slug }) {
      metaDescription
      title
      teaser {
        teaser
      }
      image {
        file {
          url
        }
      }
      fullStory {
        json
      }
      type
    }
  }
`
